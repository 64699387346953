import { useEffect } from "react";

function useScrollToSection(targetElementId, adjustmentPosition = 0) {
  useEffect(() => {
    const targetElement = document.getElementById(targetElementId);

    if (targetElement) {
      const elementRect = targetElement.getBoundingClientRect();

      const offsetTop = targetElement.offsetTop;

      window.scrollTo({
        top: offsetTop + adjustmentPosition,
        behavior: "smooth",
      });
    }
    else{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [targetElementId, adjustmentPosition]);
}

export default useScrollToSection;
