import React, { Fragment } from "react";
import DisplayCard from "../UI/Display/DisplayCard";
import CouncilDisplayCardData from "../../data/YourCouncil/CouncilDisplayCardData.json";
import SectionTitle from "../../component/UI/SectionTitles/SectionTitle";

const CouncilListBanner = () => {

    return (
        <Fragment>
           <div className="display-card-container">
              <div className="display-card-heading">
                <SectionTitle title="Partner Councils" 
                  backgroundSuperHeaderColor={'#f3f3f3f5'} 
                  //superHeaderColor = {'#a7831742'} 
                  //headerTitleColor={'#19b39f'}
                ></SectionTitle>
              </div>
              <div className="display-cards">
                {CouncilDisplayCardData.council.map((item) => (
                    <DisplayCard key={item.id} data={item} detail_display={false} />
                ))}
              </div>
           </div>
        </Fragment>
      );
    };
    
export default CouncilListBanner;