import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import SchoolsEventParallax from "../component/ServiceCards/SchoolsEvent/SchoolsEventParallax";
import SchoolsEventMessageBanner from "../component/ServiceCards/SchoolsEvent/SchoolsEventMessageBanner";
import ServiceCard from "../component/ServiceCards/SchoolsEvent/SchoolsEventServiceCards";

const SchoolsEvent = () => {
    usePageTag("Schools Event");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -264);

    return (
        <Fragment>
            <LayoutOne>
                <SchoolsEventParallax />
                <SchoolsEventMessageBanner />
                <ServiceCard />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default SchoolsEvent;