import React, { Fragment } from "react";

const UnderlineHeadingTitle = ({title, titleColor}) => {

  const defaultTitleColor = "#816518";

  const titleColorStyle = {
    color: titleColor || defaultTitleColor 
  }
    return (
        <Fragment>
          <div className="content-heading-underline-title" style={titleColorStyle}>
            {title}
          </div>
        </Fragment>
      );
    };
    
export default UnderlineHeadingTitle;


