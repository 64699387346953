import React, { Fragment } from "react";
import SectionParagraph from "../UI/SectionParagraph/SectionParagraph";

const CouncilIntroBanner = () =>{

    return (
        <Fragment>
            <div className="aboutus-banner01-wrapper">
                <div className="aboutus-banner-01-dec-block">
                    <div className="aboutus-banner-01-dec-inner">
                        <SectionParagraph title="Our Recognition" text= "At School Express, we have proudly partnered with local councils to become a trusted and recognised provider of transport services, with a special focus on school transport for children with additional needs. Our long-standing relationship with councils stands as a testament to our unwavering commitment to safety, reliability, and the well-being of the children we serve. We understand the unique requirements of SEN students and prioritise their comfort and security in every journey. When parents entrust us to their child's transportation, they can have complete peace of mind, knowing that we are the dependable choice. Your child's safety and well being are at the heart of everything we do, and our reputation as a trusted partner in the community reflects our dedication to providing a service you can rely on."></SectionParagraph>            
                    </div>
                </div>
                <div className="aboutus-banner-01-img-block">
                    <img src={process.env.PUBLIC_URL + "/assets/images/side-banner-01.jpg"} style={{ width: "100%" }} alt=""/>
                </div>
            </div>

        </Fragment>
    )
}

export default CouncilIntroBanner

