import React, { Fragment } from "react";
import SectionParagraph from "../UI/SectionParagraph/SectionParagraph";

const AboutUsBanner01 = () =>{

    return (
        <Fragment>
            <div className="aboutus-banner01-wrapper">
                <div className="aboutus-banner-01-dec-block">
                    <div className="aboutus-banner-01-dec-inner">
                        <SectionParagraph title="About Us" text= "At School Express, we are your trusted partners in ensuring safe and dependable school transportation. We are dedicated to ensuring the safety and convenience of every student's journey to and from school. With years of experience in the school transport industry, we have built a reputation for reliability and excellence. Our team of professional drivers and well-maintained fleet of vehicles are committed to providing a secure and comfortable transportation solution for students and peace of mind for parents. We understand the importance of punctuality and safety in every ride, making us the trusted choice for school transportation services in South East (Berkshire , West Berkshire, Oxfordshire, South Oxfordshire, Windsor & Maidenhead, Bracknell and London) - UK. We take pride in our mission to create a worry-free commute, allowing students to focus on what matters most, their education."></SectionParagraph>            
                    </div>
                </div>
                <div className="aboutus-banner-01-img-block">
                    <img src={process.env.PUBLIC_URL + "/assets/images/school-kids-medium-04.jpg"} style={{ width: "100%" }} alt=""/>
                </div>
            </div>

        </Fragment>
    )
}

export default AboutUsBanner01

