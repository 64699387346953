import React, { Fragment } from "react";
import messageData from "../../../data/Services/SpecialNeedsMessageData.json";
import SectionParagraph from "../../UI/SectionParagraph/SectionParagraph";

const SpecialNeedsMessageBanner = () => {
  return (
    <Fragment>
      <div className="service-message-banner-wrapper shadedBlock-02">
        <div className="service-message-banner">
          <SectionParagraph 
            title={messageData[0]["message-title"]}
            text= {messageData[0].message}
            blockBackgroundColor={"#198754"}
            backgroundSuperHeaderColor={"#189159"}
            paragraphTextColor={"#5bfdb2"}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SpecialNeedsMessageBanner;

