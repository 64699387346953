import React, { Fragment } from "react";
import SectionParagraph from "../SectionParagraph/SectionParagraph";

const DualSplitBanner = ({
  split1: {
    title: title1,
    text: text1,
    backgroundBannerColor: backgroundBannerColor1,
    backgroundSuperHeaderColor: bgColor1,
    headerTitleColor: headerColor1,
    paragraphTextColor: paraColor1,
    imageSrc: imageSrc1,
  },
  split2: {
    title: title2,
    text: text2,
    backgroundBannerColor: backgroundBannerColor2,
    backgroundSuperHeaderColor: bgColor2,
    headerTitleColor: headerColor2,
    paragraphTextColor: paraColor2,
    imageSrc: imageSrc2,
  },
}) => {
  return (
    <Fragment>
      <div className="message-banner-wrapper">
        <div className="message-banner-block message-banner-block-1" style={{ backgroundColor: backgroundBannerColor1 }}>
          {/* <BannerLogo logoAlign={'right'} height={'100px'}/> */}
          <div className="message-banner-detail">
            <SectionParagraph
              title={title1}
              text={text1}
              backgroundSuperHeaderColor={bgColor1}
              headerTitleColor={headerColor1}
              paragraphTextColor={paraColor1}
            />
          </div>
        </div>
        <div className="message-banner-block message-banner-block-2" style={{ backgroundColor: backgroundBannerColor1 }}>
          
          <div className="message-banner-img">
            <img src={imageSrc1} style={{ width: "100%" }} alt="" />
          </div>
        </div>
        <div className="message-banner-block message-banner-block-3" style={{ backgroundColor: backgroundBannerColor2 }}>
          <div className="message-banner-img">
            <img src={imageSrc2} style={{ width: "100%" }} alt="" />
          </div>
        </div>
        <div className="message-banner-block message-banner-block-4" style={{ backgroundColor: backgroundBannerColor2 }}>
          <div className="message-banner-detail2">
            <SectionParagraph
              title={title2}
              text={text2}
              backgroundSuperHeaderColor={bgColor2}
              headerTitleColor={headerColor2}
              paragraphTextColor={paraColor2}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DualSplitBanner;
