//====Run Animation (Side Div from Side) Once Per Page Refresh / Visit To Page  ====//

import React, { useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';

const SlidingDiv = ({ children, direction }) => {
  const [animate, setAnimate] = useState(false);

  const handleEnterViewport = () => {
    if (!animate) {
      setAnimate(true);
      localStorage.setItem('animationCount', '1');
    }
  };

  const slidingClass = `sliding-element ${animate ? 'animate' : ''} ${direction === 'right' ? 'slide-from-right' : 'slide-from-left'}`;

  return (
    <ScrollTrigger onEnter={handleEnterViewport}>
      <div className={slidingClass}>
        {children}
      </div>
    </ScrollTrigger>
  );
};

export default SlidingDiv;
