import React, { Fragment } from "react";

const SectionTitle = ({ title, subtitle, backgroundSuperHeaderColor, superHeaderColor, headerTitleColor }) => {
  
  const defaultBackgroundSuperHeaderColor = "#f7f7f7f5";
  const defaultSuperHeaderColor = "#0dcaf0";
  const defaultHeaderTitleColor = "#ffc107";

  const backgroundSuperHeaderStyle = {
    color: backgroundSuperHeaderColor || defaultBackgroundSuperHeaderColor,
  };

  const superHeaderStyle = {
    color: superHeaderColor || defaultSuperHeaderColor,
  };

  const headerTitleStyle = {
    color: headerTitleColor || defaultHeaderTitleColor,
  };

  return (
    <Fragment>
      <div className="sectionTitle-wrapper">
        <div className="sectionTitle-background-super-header">
          <h3 style={backgroundSuperHeaderStyle}>Safe & Secure</h3>
        </div>
        <div className="sectionTitle-super-header" style={superHeaderStyle}>
          <span>School Express</span>
        </div>
        <div className="sectionTitle">
          <h3 style={headerTitleStyle}>{title}</h3>
          <p>{subtitle}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default SectionTitle;
