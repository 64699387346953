import React, { Fragment } from "react";

const FleetCard = ({
    vehicleImg,
    category,
    carTitle,
    description,
    passengers,
    suitcases,
    onboardWifi,
    meetGreet,
    wheelchairAvailable,
    wheelchair,
    boosterseat,
    perHourRate,
    dayRate,
    dayTimeDuration,
    fullDayRate,
    fullDayDuration
  
}) => {

  const boosterSeatStatus = boosterseat ? "Booster Seat Available" : "Booster Seat Not Available";
  const meetAndGreetStatus = meetGreet ? "Meet & Greet" : "Not Available";

    return (
        <Fragment>
           <div className="fleet-card-container shadedBlock-02">
              <div className="fleet-card">
                <div className="fleet-vehicle-image"><img src={`${process.env.PUBLIC_URL}/assets/images/${vehicleImg}`}  style={{ width: "100%" }} alt=""/> </div>
                <div className="fleet-vehicle-category"><span>{category}</span></div>
                <div className="fleet-vehicle-Title"><span> {carTitle} </span> </div>
                <div className="fleet-vehicle-description"> <p>{description}</p> </div>
                <div className="fleet-vehicle-summary-block-wrapper">
                  <div className="fleet-vehicle-summary-block">
                    <div className="fleet-vehicle-summary-icon">
                        <svg width="50" height="40" viewBox="0 0 45 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											    <path d="M5 9.25C6.375 9.25 7.5 8.125 7.5 6.75C7.5 5.375 6.375 4.25 5 4.25C3.625 4.25 2.5 5.375 2.5 6.75C2.5 8.125 3.625 9.25 5 9.25ZM6.4125 10.625C5.95 10.55 5.4875 10.5 5 10.5C3.7625 10.5 2.5875 10.7625 1.525 11.225C0.6 11.625 0 12.525 0 13.5375V15.5H5.625V13.4875C5.625 12.45 5.9125 11.475 6.4125 10.625ZM25 9.25C26.375 9.25 27.5 8.125 27.5 6.75C27.5 5.375 26.375 4.25 25 4.25C23.625 4.25 22.5 5.375 22.5 6.75C22.5 8.125 23.625 9.25 25 9.25ZM30 13.5375C30 12.525 29.4 11.625 28.475 11.225C27.4125 10.7625 26.2375 10.5 25 10.5C24.5125 10.5 24.05 10.55 23.5875 10.625C24.0875 11.475 24.375 12.45 24.375 13.4875V15.5H30V13.5375ZM20.3 10.0625C18.8375 9.4125 17.0375 8.9375 15 8.9375C12.9625 8.9375 11.1625 9.425 9.7 10.0625C8.35 10.6625 7.5 12.0125 7.5 13.4875V15.5H22.5V13.4875C22.5 12.0125 21.65 10.6625 20.3 10.0625ZM10.0875 13C10.2 12.7125 10.25 12.5125 11.225 12.1375C12.4375 11.6625 13.7125 11.4375 15 11.4375C16.2875 11.4375 17.5625 11.6625 18.775 12.1375C19.7375 12.5125 19.7875 12.7125 19.9125 13H10.0875ZM15 3C15.6875 3 16.25 3.5625 16.25 4.25C16.25 4.9375 15.6875 5.5 15 5.5C14.3125 5.5 13.75 4.9375 13.75 4.25C13.75 3.5625 14.3125 3 15 3ZM15 0.5C12.925 0.5 11.25 2.175 11.25 4.25C11.25 6.325 12.925 8 15 8C17.075 8 18.75 6.325 18.75 4.25C18.75 2.175 17.075 0.5 15 0.5Z" fill="#0dcaf0"></path>
										    </svg>
                      </div>
                    <div className="fleet-vehicle-summary-detail">{passengers} Passengers</div> 
                  </div>
                  {wheelchairAvailable && (
                  <div className="fleet-vehicle-summary-block">
                     <div className="fleet-vehicle-summary-icon">
                        <svg width="40" height="40" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12,6.5a2,2,0,1,0-2-2A2,2,0,0,0,12,6.5Zm7.5,14h-1v-5a1,1,0,0,0-1-1h-5v-2h5a1,1,0,0,0,0-2h-5v-2a1,1,0,0,0-2,0v7a1,1,0,0,0,1,1h5v5a1,1,0,0,0,1,1h2a1,1,0,0,0,0-2Zm-6.8-1.6a4,4,0,0,1-7.2-2.4,4,4,0,0,1,2.4-3.66A1,1,0,1,0,7.1,11a6,6,0,1,0,7.2,9.1,1,1,0,0,0-1.6-1.2Z" fill="#0dcaf0"/>
												</svg>
                      </div>
                    <div className="fleet-vehicle-summary-detail">{wheelchair} Wheelchairs</div> 
                  </div>
                  )}
                   <div className="fleet-vehicle-summary-block">
                     <div className="fleet-vehicle-summary-icon">
                        <svg width="32" height="40" viewBox="0 0 48 48" fill="#0dcaf0" xmlns="http://www.w3.org/2000/svg"><path d="M36 33L42 4C42 4 31.5 6 31.5 10C31.5 12.5 35.5 14 34 15.5C32.5 17 27 18.5 27 22.5C27 25 28.6406 26.8594 27 28.5C25.5 30 24 29.2143 21.5 28.5C18.7 27.7 13 27 11 27.5C9 28 6.99997 29 7 32C7.00001 33.6492 8 35.5 10.5 36C13 36.5 16 34 20 34C24 34 29 37 32 37C35 37 36 33 36 33Z" fill="#0dcaf0" stroke="#333"/><path d="M11 36L8 44H41L35 36" stroke="#333"/></svg>
                      </div>
                    <div className="fleet-vehicle-summary-detail">{boosterSeatStatus}</div> 
                  </div>
                  <div className="fleet-vehicle-summary-block">
                     <div className="fleet-vehicle-summary-icon">
                        <svg width="23" height="40" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10 5.5C11.3807 5.5 12.5 4.38071 12.5 3C12.5 1.61929 11.3807 0.5 10 0.5C8.61929 0.5 7.5 1.61929 7.5 3C7.5 4.38071 8.61929 5.5 10 5.5Z" fill="#0dcaf0"></path>
														<path d="M14.8625 8.1375C14.375 7.65 13.5375 6.75 11.9125 6.75C11.65 6.75 10.1375 6.75 8.7375 6.75C5.3 6.7375 2.5 3.9375 2.5 0.5H0C0 4.45 2.6375 7.8 6.25 8.8875V25.5H8.75V18H11.25V25.5H13.75V10.5625L18.6875 15.5L20.45 13.7375L14.8625 8.1375Z" fill="#0dcaf0"></path>
												</svg>
                      </div>
                    <div className="fleet-vehicle-summary-detail">{meetAndGreetStatus}</div> 
                  </div>
                </div>
              </div>
           </div>
        </Fragment>
      );
    };
    
export default FleetCard;


