import React, { Fragment } from "react";
import HeadingTitle from "../HeadingTitle/HeadingTitle";
import Paragraph from "../SectionParagraph/Paragraph";

const SplitTextBannerItem = ({ headerTitle, paragraph }) => {
  return (
    <Fragment>
      <div className="split-txt-content-container">
        <div className="split-txt-content-block">
          <div className="split-text-block-heading">
            <HeadingTitle title={headerTitle} titleColor={"#19b39f"} />
          </div>
          <div className="split-text-block-content">
            <Paragraph text={paragraph} paragraphTextColor={"#816518"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SplitTextBannerItem;
