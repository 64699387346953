import React, { Fragment } from "react";
import SplitBanner from "../UI/Banners/SplitBanner";

const CouncilContactUsBanner = () => {
  return (
    <Fragment>
      <SplitBanner
        title="Contact Us"
        text="Rest assured, we are proud affiliates of your council, ensuring your peace of mind and confidence in our services. Your trust is our commitment"
        backgroundBannerColor="#ffc107"
        backgroundSuperHeaderColor="#a7831742"
        headerTitleColor="#19b39f"
        paragraphTextColor="#816518"
        imageSrc={process.env.PUBLIC_URL + "/assets/images/banner-img-09.jpg"}
      />
    </Fragment>
  );
};

export default CouncilContactUsBanner;
