import React, { Fragment, useEffect  } from "react";
import ServicesData from "../../../data/Services/service-one.json";
import SlidingDiv from "../../UI/BlockAnimation/SlidingBlockAnimation/home-two/SlidingDiv";
import UnderlineHeadingTitle from "../../UI/HeadingTitle/UnderlineHeadingTitle";
import Paragraph from "../../UI/SectionParagraph/Paragraph";

const OurServicesCards = () => {
    useEffect(() => {
      localStorage.removeItem('animationCount'); // Reset animation count on page visit
    }, []);

  return (

    <Fragment>
       <div className="services-banner-container">
        {ServicesData.map((service, index) => (
          <SlidingDiv key={service.id} direction={index % 2 === 1 ? 'right' : 'left'}>
            <div className="service-card-banner rounded-div">
              <div className="service-banner-message shadedBlock-02 banner-blackboard">
                  <UnderlineHeadingTitle title={service.title} titleColor={"#b1a072"} />
                  <Paragraph text={service.description} paragraphTextColor={"#b89841"} paragraphTextStyleClass={"chalk-font-effect-01"} />
              </div>
              <div className="service-banner-image">
                <img className="service-banner-img" src={process.env.PUBLIC_URL + `/assets/images/${service.serviceImg}`} alt="service-img-tag" />
              </div>
            </div>
          </SlidingDiv>
        ))}
      </div>
    </Fragment>

  );
};

export default OurServicesCards;



