import React, { Fragment} from "react";


const Paragraph = ({ text, paragraphTextColor, paragraphTextStyleClass, textFontSize, textJustify}) => {

const defaultParagraphTextColor = "#ababab";

const paragraphTextStyle = {
  color: paragraphTextColor || defaultParagraphTextColor
 }

 if (textFontSize) {
  paragraphTextStyle.fontSize = textFontSize;
 }

 if (textJustify) {
  paragraphTextStyle.textAlign = textJustify;
 }
 
  return (
    <Fragment>
      <div className="paragraph-block">
        <div className={`paragraph-desc paragraph-block-text ${paragraphTextStyleClass}`}>
          <p style={paragraphTextStyleClass ? {} : paragraphTextStyle} className={paragraphTextStyleClass}>{text}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Paragraph;