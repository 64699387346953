export const MsalConfiguration = {
    auth: {
        clientId: "",
        authority: "",
        redirectUri: "http://localhost:3000", 
        validateAuthority: false,
        postLogoutRedirectUri: "http://localhost:3000/authlogin",
        knownAuthorities: [""],
        scopes: [""], 
      },
      cache: {
        cacheLocation: "sessionStorage", 
        storeAuthStateInCookie: false,
      }
}