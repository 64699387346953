import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import ContactusParallax from "../component/Contact/ContactusParallax";
import ContactusFormBanner from "../component/Contact/ContactusFormBanner";
import ContactusPanel from "../component/Contact/ContactusPanel";
import ContactusMapBanner from "../component/Contact/ContactusMapBanner";

const ContactUs = () => {
    usePageTag("Contact Us");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -110);

    return (
        <Fragment>
            <LayoutOne>
                <ContactusParallax />
                <ContactusFormBanner />
                <ContactusPanel />
                <ContactusMapBanner />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default ContactUs;