import React from "react";

import Home from "../../pages/Home";
import About from "../../pages/About";
import ContactUs from "../../pages/ContactUs";
import Error from "../../component/Errors/Error";
import OurServices from "../../pages/OurServices";
import SpecialNeedsTransport from "../../pages/SpecialNeedsTransport";
import SchoolTransport from "../../pages/SchoolTransport";
// import LocalCouncilTransport from "../../pages/LocalCouncilTransport";
import Wheelchair from "../../pages/Wheelchair";
import CareHomeTransport from "../../pages/CareHomeTransport";
import SchoolsEvent from "../../pages/SchoolsEvent";
import YourCouncil from "../../pages/YourCouncil";
import Fleet from "../../pages/Fleet";

export const routes = [
  { path: "/", element: <Home /> },
  { path: "/home", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/services", element: <OurServices /> },
  { path: "/services/school-transport", element: <SchoolTransport /> },
  { path: "/services/additional-needs-transport", element: <SpecialNeedsTransport /> },
  // { path: "/services/local-council-transport", element: <LocalCouncilTransport /> },
  { path: "/services/wheelchair", element: <Wheelchair /> },
  { path: "/services/social-care-transport", element: <CareHomeTransport /> },
  { path: "/services/school-event", element: <SchoolsEvent /> },
  { path: "/fleet", element: <Fleet /> },
  { path: "/your-council", element: <YourCouncil /> },
  { path: "/contactus", element: <ContactUs /> },
  { path: "*", element: <Error /> },
];
