import { useState, useEffect, useCallback } from "react";
import ParallaxDynamicLayer from "../../UI/Parallax/ParallaxDynamicLayer";
import HomeServiceData from "../../../data/Parallax/ParallaxBanner.json"; 

const SchoolsEventParallax = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [selectedImage, setImageBackground] = useState("");

  const calculateStartingPosition = useCallback(() => {
    if (viewportWidth <= 791) { 
      if (viewportWidth === 600 && viewportHeight === 1024) { return -260; } 
      else if (viewportWidth >= 768 && viewportHeight === 1024) { return -90; }   
      else return -170;  } 
    else if (viewportWidth >= 792 && viewportWidth <=999) { 
      if (viewportWidth >= 792 && viewportHeight === 1031) { return -120; }
    } 
    else if (viewportWidth >= 1000 && viewportWidth <= 1031 && viewportHeight >= 800 && viewportHeight <= 900) { return -290; }
    else if (viewportWidth >= 1000 && viewportWidth <= 1040 &&  viewportHeight <= 1400) { return -120; }
    else if (viewportWidth === 1280 && viewportWidth <= 1300 && viewportHeight >= 800 && viewportHeight <= 1000) { return 0; }
    else { return -100; }

  }, [viewportWidth, viewportHeight]);

  const [startingPosition, setStartingPosition] = useState(
    calculateStartingPosition()
  );

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    setStartingPosition(calculateStartingPosition());

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [calculateStartingPosition]);

  useEffect(() => {
    let imageUrl;

    if (viewportWidth <= 791) {
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/school-event-mobile-small-01.jpg`;
    } else if (viewportWidth >= 792 && viewportWidth <= 1031) {
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/school-event-mobile-medium-01.jpg`;
    } else if (viewportWidth >= 1032 && viewportWidth <= 1199) {
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/school-event-mobile-medium-01.jpg`;
    } else {
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/school-kids-large-04.jpg`;
    }

    if (viewportWidth === 768 && viewportHeight === 1024){
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/school-event-mobile-medium-01.jpg`;
    }

    setImageBackground(imageUrl);
  }, [viewportWidth]);

  return (
    <div>
      <ParallaxDynamicLayer
        data={HomeServiceData}
        imageUrl={selectedImage}
        startingPosition = {startingPosition}
      />
    </div>
  );
};

export default SchoolsEventParallax;



