import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import FleetParallax from "../component/Fleet/FleetParallax";
import FleetHeaderBanner from "../component/Fleet/FleetHeaderBanner";
import FleetBlock from "../component/Fleet/FleetBlock";
import FleetContactUsBanner from "../component/Fleet/FleetContactUsBanner";
import OurHighlightsFleet from "../component/OurHighlights/OurHighlightsFleet";


const Fleet = () => {
    usePageTag("Fleet");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -264);

    return (
        <Fragment>
            <LayoutOne>
                <FleetParallax />
                <FleetHeaderBanner/>
                <FleetBlock />
                <OurHighlightsFleet />
                <FleetContactUsBanner />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default Fleet;