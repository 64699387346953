import React, { Fragment } from "react";
import SplitBanner from "../../UI/Banners/SplitBanner";

const HomeContactUsBanner = () => {
  return (
    <Fragment>
      <SplitBanner
        title="Contact Us"
        text="Experience luxury travel with the assurance of safety. Parents trust us for our commitment to well-being and elegance on every journey. Connect today for a sophisticated and secure ride. Together, let's prioritise peace of mind."
        backgroundBannerColor="#ffc107"
        backgroundSuperHeaderColor="#a7831742"
        headerTitleColor="#19b39f"
        paragraphTextColor="#816518"
        imageSrc={process.env.PUBLIC_URL + "/assets/images/banner-img-01.jpg"}
      />
    </Fragment>
  );
};

export default HomeContactUsBanner;
