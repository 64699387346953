import React, { Fragment } from "react";

const DisplayCard = ({ data, detail_display }) => {

    const handleCardClick = () => {
        if (data.extarnelLink) {
            window.open(data.extarnelLink, "_blank");
        }
    };

    return (
        <Fragment>
            <div className="display-card shadedBlock-02" onClick={handleCardClick} style={{ cursor: data.extarnelLink ? "pointer" : "default" }}>
                <div className="display-card-img-wrapper">
                    <img src={process.env.PUBLIC_URL + '/assets/images/' + data.Img} alt="" style={{ width: "100%" }}/>
                </div>
                <div className="display-card-title">
                    {detail_display ? data.Title : null}
                </div>
            </div>
        </Fragment>
    );
};

export default DisplayCard;