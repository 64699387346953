import { useState, useEffect, useCallback } from "react";
import ParallaxDynamicLayer from "../../UI/Parallax/ParallaxDynamicLayer";
import HomeServiceData from "../../../data/Parallax/ParallaxBanner.json"; 

const WheelChairParallax = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [selectedImage, setImageBackground] = useState("");

  const calculateStartingPosition = useCallback(() => {
    if (viewportWidth <= 791) { return 0; } 
    else if (viewportWidth >= 1000 && viewportWidth <= 1031 && viewportHeight >= 800 && viewportHeight <= 900) { return 0; } 
    else { return 0; }

  }, [viewportWidth, viewportHeight]);

  const [startingPosition, setStartingPosition] = useState(
    calculateStartingPosition()
  );

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    setStartingPosition(calculateStartingPosition());

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [calculateStartingPosition]);

  useEffect(() => {
    let imageUrl;

    if (viewportWidth <= 791) {
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/header-mobile-small-wheelchair-01.jpg`;
    } else if (viewportWidth >= 792 && viewportWidth <= 1031) {
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/header-mobile-medium-wheelchair-01.jpg`;
    } else if (viewportWidth >= 1032 && viewportWidth <= 1199) {
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/services-wheelchair-header-img-01.jpg`;
    } else { 
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/services-wheelchair-header-img-01.jpg`;
    }

    if (viewportWidth === 768 && viewportHeight === 1024){
      imageUrl = `${process.env.PUBLIC_URL}/assets/images/header-mobile-medium-wheelchair-01.jpg`;
    }

    setImageBackground(imageUrl);
  }, [viewportWidth]);

  return (
    <div>
      <ParallaxDynamicLayer
        data={HomeServiceData}
        imageUrl={selectedImage}
        startingPosition = {startingPosition}
      />
    </div>
  );
};

export default WheelChairParallax;



