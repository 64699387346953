import React, { Fragment } from 'react';
import ParallaxScrollLayer from "../../component/UI/Parallax/ParallaxScrollLayer";

const OurMissionBanner = () => {
  return (
    <Fragment>
      <ParallaxScrollLayer
        // message={'Empowering Education Through Safe and Reliable Transportation'}
        imageUrl={`${process.env.PUBLIC_URL}/assets/images/banner-img-van-large-02.jpg`}
        backgroundPositionY={'bottom'}
        startingPosition={'-10'}
        bannerHeight={'60vh'}
      />
      {/* <img src={process.env.PUBLIC_URL + '/assets/images/banner-img-van-large-02.jpg'} style={{ width: '100%' }} alt="" /> */}
    </Fragment>
  );
};

export default OurMissionBanner;

