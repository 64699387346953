import React, { Fragment } from "react";
import SectionParagraph from "../SectionParagraph/SectionParagraph";
import PrimaryButton from "../../UI/Button/PrimaryButton";
// import BannerLogo from "../../UI/Banners/BannerLogo";

const SplitTextBanner = ({
    block1: {
        title,
        text,
        backgroundSuperHeaderColor,
        superHeaderColor,
        headerTitleColor,
        paragraphTextColor, 
        paragraphFontSize,
        block1BackgroundColor
    },
    block2: {
        blockContent
    },
    button: {
        isButtonRequired,
        buttonText
    }   
}) =>{

    return(
        <Fragment>
            <div className="split-txt-banner-container">
                <div className="split-txt-block split-txt-block1" style={{ backgroundColor: block1BackgroundColor }}>
                {/* <BannerLogo logoAlign={'right'} height={'100px'}/> */}
                    <div className="split-txt-content-block1">
                        <SectionParagraph
                            title = {title}
                            text = {text}
                            backgroundSuperHeaderColor = {backgroundSuperHeaderColor}
                            superHeaderColor = {superHeaderColor}
                            headerTitleColor = {headerTitleColor}
                            paragraphTextColor = {paragraphTextColor}
                            paragraphFontSize = {paragraphFontSize}
                        />
                        <div className="split-txt-content-btn">
                        {isButtonRequired && <PrimaryButton text = {buttonText}  />}
                        </div>
                    </div>
                        
                </div>
                <div className="split-txt-block split-txt-block2">
                    <div className="split-txt-content-block2">
                       {blockContent}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SplitTextBanner;