import React, { Fragment } from "react";
import DualSplitBanner from "../UI/Banners/DualSplitBanner";
import Data from "../../data/About/AboutUsBannerData.json";

const AboutUsBanner02 = () => {
  return (
    <Fragment>
      <DualSplitBanner
        split1={{
          title: Data.split1.title,
          text: Data.split1.text,
          backgroundBannerColor: "#ffc107",
          backgroundSuperHeaderColor: "#a7831742",
          headerTitleColor: "#19b39f",
          paragraphTextColor: "#816518",
          imageSrc:`${process.env.PUBLIC_URL}/assets/images/${Data.split1.image}`,
        }}
        split2={{
          title: Data.split2.title,
          text: Data.split2.text,
          backgroundBannerColor: "#ffc107",
          backgroundSuperHeaderColor: "#a7831742",
          headerTitleColor: "#19b39f",
          paragraphTextColor: "#816518",
          imageSrc: `${process.env.PUBLIC_URL}/assets/images/${Data.split2.image}`
        }}
      />
    </Fragment>
  );
};

export default AboutUsBanner02;
