import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import SchoolTransportParallax from "../component/ServiceCards/SchoolTransport/SchoolTransportParallax";
import SchoolTransportMessageBanner from "../component/ServiceCards/SchoolTransport/SchoolTransportMessageBanner";
import ServiceCard from "../component/ServiceCards/SchoolTransport/SchoolTransportServiceCards";

const SchoolTransport = () => {
    usePageTag("School Transport");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -264);

    return (
        <Fragment>
            <LayoutOne>
                <SchoolTransportParallax />
                <SchoolTransportMessageBanner />
                <ServiceCard />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default SchoolTransport;