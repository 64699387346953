import React, { Fragment } from "react";

const BannerLogo = ({ logoAlign, height }) => {

    const bannerBlockStyle = {
        display: 'flex',
        justifyContent: logoAlign === 'left' ? 'flex-start' : 'flex-end',
        height: height
    };

    return (
        <Fragment>
            <div className="bannerLogoWrapper" style={bannerBlockStyle} >
                <img src={process.env.PUBLIC_URL + '/assets/images/banner-logo-05.jpg'} alt="" />
            </div>
        </Fragment>
      );
    };
    
export default BannerLogo;