import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "./configurations/App/AppRoutes";
import Spinner from "./component/UI/LoadingSpiner/Spinner";
import "./assets/css/App.css";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  
  
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      )}
    </div>
  );
}

export default App;
