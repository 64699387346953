import React, { Fragment } from "react";
import SplitBlockBanner from "../UI/Banners/SplitBlockBanner";
import SectionTitle from "../UI/SectionTitles/SectionTitle";
import Paragraph from "../UI/SectionParagraph/Paragraph";

const ContactusMapBanner = () => {
  return (
    <Fragment>
      <SplitBlockBanner 
        blockImg={process.env.PUBLIC_URL + "/assets/images/map-01.jpg"} 
        blockContent={
            <div className="block-content-wrapper">
                <div className="block-content-title">
                    <SectionTitle
                        title={"Find Us On Map"}
                        backgroundSuperHeaderColor={'#a7831742'} 
                        superHeaderColor = {"#0dcaf0"}
                        headerTitleColor = {"#19b39f"}

                    />
                </div>
                <div className="block-content-detail">
                    <Paragraph 
                        text={"We are based In Reading, Please find us on Map"} 
                        paragraphTextColor={"#816518"} 
                    />
                </div>
                
                
            </div>
        } />
    </Fragment>
  );
};

export default ContactusMapBanner;


