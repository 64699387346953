import React, { Fragment } from "react";
import FleetCard from "./FleetCard";
import FleetData from "../../data/Fleet/FleetData.json";

const MyFleet = () => {

  return (
    <Fragment>
      <div className="our-fleet-container">
        {FleetData.fleet.map((vehicle, index) => (
          <FleetCard
            key={index}
            vehicleImg={vehicle.vehicleImg}
            category={vehicle.category}
            carTitle={vehicle.carTitle}
            description={vehicle.description}
            passengers={vehicle.passengers}
            suitcases={vehicle.suitcases}
            onboardWifi={vehicle.onboard_wifi}
            meetGreet={vehicle.meetGreet}
            wheelchairAvailable={vehicle.isWheelchairAvailable}
            wheelchair={vehicle.wheelchair}
            boosterseat={vehicle.booster_seat}
            perHourRate={vehicle.per_hour_rate}
            dayRate={vehicle.day_rate}
            dayTimeDuration={vehicle.day_time_duration}
            fullDayRate={vehicle.full_day_rate}
            fullDayDuration={vehicle.full_day_duration}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default MyFleet;