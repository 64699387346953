import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import WheelChairParallax from "../component/ServiceCards/Wheelchair/WheelchairParallax";
import WheelchairMessageBanner from "../component/ServiceCards/Wheelchair/WheelchairMessageBanner";
import ServiceCard from "../component/ServiceCards/Wheelchair/WheelchairServiceCards";

const Wheelchair = () => {
    usePageTag("Wheelchair");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -264);
    
    return (
        <Fragment>
            <LayoutOne>
                <WheelChairParallax />
                <WheelchairMessageBanner />
                <ServiceCard />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default Wheelchair;