import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalConfiguration } from "./configurations/Security/MsalConfiguration";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/plugins/font-awesome/css/font-awesome.min.css"; 
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import "./assets/icon/elegant-icons/style.css";
import "./assets/css/normalize.css"; 
import "./assets/css/style.css";   
import "./assets/css/responsive.css";  

import App from './App';
import reportWebVitals from './reportWebVitals';
import { ParallaxProvider } from "react-scroll-parallax";

const instance = new PublicClientApplication(MsalConfiguration);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
    <React.StrictMode>
      <BrowserRouter>
        <MsalProvider instance={instance}>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
        </MsalProvider>
      </BrowserRouter>
    </React.StrictMode>
  
);

reportWebVitals();
