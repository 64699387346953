import React, { Fragment } from "react";
import BannerLogo from "../../UI/Banners/BannerLogo";

const SplitBlockBanner = ({
        blockImg,
        blockContent
}) =>{

    return(
        <Fragment>
            <div className="split-block-banner-container">
                <div className="split-block split-block-inner1">
                    <img src={blockImg} style={{ width: "100%" }} alt=""/>
                </div>
                <div className="split-block split-block-inner2">
                <BannerLogo logoAlign={'left'} height={'120px'}/>
                    <div className="split-block-content">
                       {blockContent}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SplitBlockBanner;

