import React from 'react';

const Header = () => {
	return (
 
    <div className="header_top_area">
        <div className="container">
            <div className="header-contact-container">
                <div className="header-top-left">
                </div>
                <div className="header-top-right">
                     <div className="header-contact-block header-phone">
                        <div className = "header-contact-block-icon">
                            <i className="icon_phone theme-icon-color" />
                        </div>
                        <div className= "header-contact-block-info">
                            <a href="tel:01491414040">01491 414 040</a>
                        </div>
                    </div>
                    <div className="header-contact-block header-email">
                        <div className = "header-contact-block-icon">
                            <i className="icon_mail_alt theme-icon-color" />
                        </div>
                        <div className= "header-contact-block-info">
                            <a href="mailto:office@schoolexpressuk.com">office@schoolexpressuk.com</a>
                        </div>
                    </div>
                    <div className="header-contact-block header-contact">
                        <div className = "header-contact-block-icon">
                            <i className="icon_pin_alt theme-icon-color"/>
                        </div>
                        <div className= "header-contact-block-info">
                            <a href="geo:51.461670,-0.933910">UK | Reading</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
	)
}


export default Header;



