import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import CareHomeParallax from "../component/ServiceCards/CareHomeTransport/CareHomeParallax";
import CareHomeMessageBanner from "../component/ServiceCards/CareHomeTransport/CareHomeMessageBanner";
import ServiceCard from "../component/ServiceCards/CareHomeTransport/CareHomeServiceCards";

const CareHomeTransport = () => {
    usePageTag("Care Home Transport");
    usePageTag("Social Care Transport");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -264);

    return (
        <Fragment>
            <LayoutOne>
                <CareHomeParallax/>
                <CareHomeMessageBanner />
                <ServiceCard />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default CareHomeTransport;