import React, { Fragment } from "react";
import SplitTextBanner from "../UI/Banners/SplitTextBanner";
import SplitHighlightBannerItem from "../UI/Banners/SplitHighlightBannerItem";
import SectionTitle from "../../component/UI/SectionTitles/SectionTitle";
import Data from "../../data/Fleet/FleetBannerData.json";

const FleetHeaderBanner = () => {
  return (
    <Fragment>
      <SplitTextBanner
        block1={{
          title: Data.block1.title,
          text: Data.block1.text
        }}
        block2={{
          blockContent: (
            <div>
              <SectionTitle
                title="Our Values"
                backgroundSuperHeaderColor={'#a7831742'}
                // superHeaderColor = {'#a7831742'}
                headerTitleColor={'#19b39f'}
              ></SectionTitle>
              {Data.block2.map((item, index) => (
                <SplitHighlightBannerItem
                  key={index}
                  content={item.headerTitle}
                />
              ))}
            </div>
          )
        }}
        button={{
          isButtonRequired: false,
          buttonText: "Click Me!"
        }}
      />
    </Fragment>
  );
};

export default FleetHeaderBanner;

