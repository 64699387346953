import React, { Fragment } from "react";

const HeadingTitle = ({title, titleColor, textFontSize}) => {

  const defaultTitleColor = "#816518";

  const titleStyle = {
    color: titleColor || defaultTitleColor 
  }

  if (textFontSize) {
    titleStyle.fontSize = textFontSize;
  }

    return (
        <Fragment>
          <div className="content-heading-wrapper" style={titleStyle}>
            {title}
          </div>
        </Fragment>
      );
    };
    
export default HeadingTitle;