import React from 'react';  


function ParallaxMessageItem(props) {
    return (
 
        <div className="parallaxItem item"> 
            <div className="parallax-box">
                <div className="parallax_content">  
                    <p>{props.note}</p>
                </div>
            </div>
        </div> 

    );
}

export default ParallaxMessageItem;