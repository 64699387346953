import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import OurServicesParallax from "../component/ServiceCards/OurServices/OurServicesParallax";
import OurServicesMessageBanner from "../component/ServiceCards/OurServices/OurServicesMessageBanner";
import OurServicesCards from "../component/ServiceCards/OurServices/OurServicesCards";
import ServicesContactUsBanner from "../component/OurServices/ServicesContactUsBanner";

const OurServices = () => {
    usePageTag("Our Services");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -264);

    return (
        <Fragment>
          <LayoutOne>
            <OurServicesParallax />
            <OurServicesMessageBanner/>
            <OurServicesCards />
            <ServicesContactUsBanner />
          </LayoutOne>
        </Fragment>
      );
    };
    
export default OurServices;