import React, { useEffect, useState } from "react";
import { ParallaxBanner } from "react-scroll-parallax";

const ParallaxScrollLayer = ({ message, imageUrl, backgroundPositionY, startingPosition, bannerHeight }) => {
    const [bannerPosition, setBannerPosition] = useState(
        `center ${backgroundPositionY} ${startingPosition}px` || "center bottom 0px"
      );

    useEffect(() => {
        setBannerPosition(`center ${backgroundPositionY} ${startingPosition}px` ||"center bottom 0px");

      }, [startingPosition, backgroundPositionY]);

  return (
    <div className="parallax-banner-wrapper">
         <style>
        {`
          .parallax-banner-container {
            height: var(--banner-height, ${bannerHeight || '100vh'});
          }
        `}
      </style> 
      
      <ParallaxBanner
        layers={[
          { image: imageUrl || '', speed: -25, 
          style: {
            backgroundPosition: bannerPosition,
            transform: `translateY(0px)`,
            height: '100vh' }  
          },
          {
            speed: -15,
            children: (
              <div className="">
                <h1 className="text">{message}</h1>
              </div>
            ),
          },
        ]}
        className="parallax-banner-container"
      />
  </div>
  );
};

export default ParallaxScrollLayer;

