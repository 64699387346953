import React from 'react';
import PuffLoader   from "react-spinners/PuffLoader";

const Spinner = () => {
    const color = "#ffc107";

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "#ffc107",
      };

  return (
    <div className="loading-spiner-wrapper">
        <div className="spinner">
            <PuffLoader   
                color={color}
                loading={true}
                size={150}
                cssOverride={override}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    </div>
  );
};

export default Spinner;


