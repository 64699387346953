import React, { Fragment } from "react";
import SectionParagraph from "../../UI/SectionParagraph/SectionParagraph";

const OurServicesMessageBanner = () =>{
    
    return(
        <Fragment>
            <div className="ourServicesMessageWrapper shadedBlock-02">
                <div className="ourServiceMessage">
                    <SectionParagraph 
                        title={"Welcome"}
                        text={"You can expect a professional and experienced chauffeur driver on your wedding day. The chauffeur will be immaculately dressed in a smart suit and tie and will arrive at least 15 minutes before the appointment time, to help ease any nerves. Whatever car you choose, from the magnificent Mercedes-Benz S-Class, to the beautiful Bentley or the regal Rolls-Royce, you can expect a freshly valeted car inside and out along with free wedding car ribbons."}
                        // blockBackgroundColor={"#198754"}
                        backgroundSuperHeaderColor={"#189159"}
                        paragraphTextColor={"#5bfdb2"}
                />
                </div>
            </div>
        </Fragment>
    );
}

export default OurServicesMessageBanner;