import React, { Fragment } from "react";
import SectionParagraph from "../SectionParagraph/SectionParagraph";

const SplitBanner = ({
  title,
  text,
  backgroundBannerColor,
  backgroundSuperHeaderColor,
  headerTitleColor,
  paragraphTextColor,
  imageSrc,
}) => {
  return (
    <Fragment>
      <div className="message-banner-wrapper">
        <div className="message-banner-block message-banner-block-1" style={{ backgroundColor: backgroundBannerColor }}>
          <div className="message-banner-detail">
            <SectionParagraph
              title={title}
              text={text}
              backgroundSuperHeaderColor={backgroundSuperHeaderColor}
              headerTitleColor={headerTitleColor}
              paragraphTextColor={paragraphTextColor}
            />
          </div>
        </div>
        <div className="message-banner-block message-banner-block-2" >
          <div className="message-banner-img">
            <img src={imageSrc} style={{ width: "100%" }} alt="" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SplitBanner;
