import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import YourCouncilParallax from "../component/YourCouncil/YourCouncilParallax";
import CouncilContactUsBanner from "../component/YourCouncil/CouncilContactUsBanner";
import CouncilIntroBanner from "../component/YourCouncil/CouncilIntroBanner";
import CouncilListBanner from "../component/YourCouncil/CouncilListBanner";

const YourCouncil = () => {
    usePageTag("Your Council");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -264);

    return (
        <Fragment>
            <LayoutOne>
                <YourCouncilParallax />
                <CouncilIntroBanner />
                <CouncilListBanner />
                <CouncilContactUsBanner />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default YourCouncil;