import React, { useState, useEffect } from "react";
import SlickSlider from "../Carousel/Slick";
import PrallaxMessageItem from "./ParallaxMessageItem";

const ParallaxDynamicLayer = ({ data, imageUrl, startingPosition }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [backgroundPosition, setBackgroundPosition] = useState(
    `center ${(scrollPosition * 0.2) + startingPosition}px)`
  );
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [headerTopBarHeight, setheaderTopBarHeight] = useState(0);

  useEffect(() => {
    const navbar = document.querySelector(".navigation");
    const headerTopBar = document.querySelector(".header_top_area");

    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }

    if (headerTopBar) {
      setheaderTopBarHeight(headerTopBar.offsetHeight);
    }

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Smoothly update backgroundPosition using requestAnimationFrame
    let animationFrameId;

    const updateBackgroundPosition = () => {
      const newPosition = `center ${(scrollPosition * 0.2) + startingPosition}px`;

      setBackgroundPosition(newPosition);
      animationFrameId = requestAnimationFrame(updateBackgroundPosition);
    };

    updateBackgroundPosition();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [scrollPosition, startingPosition]);

  const availableViewportHeight = window.innerHeight - (navbarHeight + headerTopBarHeight);

  const parallaxStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: backgroundPosition, 
    //backgroundPosition: `center ${-scrollPosition * 0.2}px`,
    backgroundSize: "cover",
    height: availableViewportHeight + "px",
    backgroundRepeat: "no-repeat",
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="parallax-container" style={parallaxStyle}>
      <section className="parallax-section parallax-msg-wrapper">
        <div className="parallax-slider-wrapper">
          <SlickSlider settings={settings}>
            {data.map((dataItem) => (
              <div key={dataItem.id}>
                <PrallaxMessageItem note={dataItem.message} />
              </div>
            ))}
          </SlickSlider>
        </div>
      </section>
    </div>
  );
};

export default ParallaxDynamicLayer;



