import React, { Fragment } from "react";

const PrimaryButton = ({
    buttonColor,
    text
}) => {

    buttonColor = "#19b39f"

    return (
        <Fragment>
           <div className="content-btn">
                <button style={{ backgroundColor: buttonColor }}>{text}</button>
            </div>
        </Fragment>
      );
    };
    
export default PrimaryButton;