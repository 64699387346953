import React, { Fragment } from "react";
import SplitTextBanner from "../UI/Banners/SplitTextBanner";
import ContactFormItem from "./ContactFormItem";

const ContactusFormBanner = () => {
  return (
    <Fragment>
      <SplitTextBanner
        block1={{
          title: "Write Us a Message",
          text: "We're here to support your journey! For inquiries or assistance, reach out through the contact form or give us a call...",
          backgroundSuperHeaderColor: "#189159",
          superHeaderColor: "",
          headerTitleColor: "",
          paragraphTextColor: "#5bfdb2",
          block1BackgroundColor: "#198754"  
        }}
        block2={{
          blockContent: (
            <div>
              <ContactFormItem />
            </div>
          )
        }}
        button={{ 
          isButtonRequired: false, 
          buttonText: "Click Me!" 
      }}
      />
    </Fragment>
  );
};

export default ContactusFormBanner;
