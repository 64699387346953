import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import SpecialNeedsParallax from "../component/ServiceCards/SpecialNeedsTransport/SpecialNeedsParallax";
import SpecialNeedsMessageBanner from "../component/ServiceCards/SpecialNeedsTransport/SpecialNeedsMessageBanner";
import ServiceCard from "../component/ServiceCards/SpecialNeedsTransport/SpecialNeedsServiceCards";

const SpecialNeedsTransport = () => {
    usePageTag("Additional Needs");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -264);

    return (
        <Fragment>
            <LayoutOne>
                <SpecialNeedsParallax />
                <SpecialNeedsMessageBanner />
                <ServiceCard />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default SpecialNeedsTransport;