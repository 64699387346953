import React, { Fragment } from "react";
import SplitBanner from "../UI/Banners/SplitBanner";

const AboutContactUsBanner = () => {
  return (
    <Fragment>
      <SplitBanner
        title="Contact Us"
        text="Discover School Express, where transportation excellence meets innovation. Contact us to learn more and connect with us today."
        backgroundBannerColor="#ffc107"
        backgroundSuperHeaderColor="#a7831742"
        headerTitleColor="#19b39f"
        paragraphTextColor="#816518"
        imageSrc={process.env.PUBLIC_URL + "/assets/images/banner-img-04.jpg"}
      />
    </Fragment>
  );
};

export default AboutContactUsBanner;
