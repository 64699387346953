import React, { Fragment } from "react";
import SplitBanner from "../UI/Banners/SplitBanner";

const ServicesContactUsBanner = () => {
  return (
    <Fragment>
      <SplitBanner
        title="Contact Us"
        text="At School Express, we take pride in being your trusted source for the best safe and reliable transport services in the industry. Our commitment to excellence ensures that every journey with us is a journey you can rely on. If you're ready to experience the pinnacle of transportation services, don't hesitate to contact us today. Your satisfaction is our guarantee."
        backgroundBannerColor="#ffc107"
        backgroundSuperHeaderColor="#a7831742"
        headerTitleColor="#19b39f"
        paragraphTextColor="#816518"
        imageSrc={process.env.PUBLIC_URL + "/assets/images/banner-img-12.jpg"}
      />
    </Fragment>
  );
};

export default ServicesContactUsBanner;
