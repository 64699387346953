import React, { Fragment } from "react";
import SectionTitle from "../SectionTitles/SectionTitle";
import Paragraph from "../../UI/SectionParagraph/Paragraph";

const SectionParagraph = ({ title, text, blockBackgroundColor, backgroundSuperHeaderColor, superHeaderColor, headerTitleColor, paragraphTextColor, paragraphFontSize }) => {

//const defaultBlockBackgroundColor = "#fbfbfb";

 const blockBackgroundColorStyle = blockBackgroundColor ? {
  backgroundColor: blockBackgroundColor
} : {};

  return (
    <Fragment>
      <div className="paragraph-block" style={blockBackgroundColorStyle} >
        <div className="paragraph-title">
          <SectionTitle 
            title={title} 
            backgroundSuperHeaderColor={backgroundSuperHeaderColor} 
            superHeaderColor = {superHeaderColor}
            headerTitleColor = {headerTitleColor}
          ></SectionTitle>
        </div>
        <div className="paragraph-text">
          <Paragraph text={text} paragraphTextColor={paragraphTextColor} textFontSize={paragraphFontSize}  />
        </div>
      </div>
    </Fragment>
  );
};

export default SectionParagraph;



