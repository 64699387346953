import React from 'react';
import SlickSlider from "../../UI/Carousel/Slick";
import ServicesData from "../../../data/Services/service-one.json"; 
import ServiceItem from "./ServiceItem";
import SectionTitle from '../../UI/SectionTitles/SectionTitle';


const NextArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="arrow_right"/></button>
    )
};

const PrevArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="arrow_left"/></button>
    )
};

const Service = () => {  

    const settings = {
        dots: false,
        arrows:true,
        infinite: true, 
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
          responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                }
              } 
 
            ]
      };

 
        return (

        <section className="service_section">
            <div className="container"> 
                {/* Heading */}
                <SectionTitle 
                    backgroundSuperHeaderColor="#edededf5"
                    title="Our Services" 
                />
                {/* End: Heading */}

                <div className="row" id="service"> 
                    <div className="col"> 
                        <SlickSlider settings={settings}>
                            {
                                ServicesData.map(service => (
                                    <div key={service.id}>
                                        <ServiceItem 
                                        key={service.id} 
                                        id={service.id} 
                                        title={service.title} 
                                        serviceURL={service.serviceUrl}
                                        shortDesc={service.shortDesc} 
                                        thumb={service.thumb}  
                                        icon={service.icon}  
                                        btnText={service.btnText} 
                                        />
                                    </div> 
                                ))
                            }
                        </SlickSlider>
                    </div> 
                </div> 
            </div> 
        </section>    
        );
    }
;

export default Service;

