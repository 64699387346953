import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import usePageTag from "../hooks/usePageTag";

import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import AboutusParallax from "../component/About/AboutusParallax";
import AboutusHeaderBanner from "../component/About/AboutusHeaderBanner";
// import Testimonials from "../component/Testimonials/Testimonials";
import OurHighlightsAbout from "../component/OurHighlights/OurHighlightsAbout";
import AboutContactUsBanner from "../component/About/AboutContactUsBanner";
import OurMissionBanner from "../component/OurMission/OurMissionBanner";

const About = () => {
    usePageTag("About Us");

    const location = useLocation();

    useScrollToSection(location.hash.slice(1), -110);

    return (
        <Fragment>
            <LayoutOne>
                <AboutusParallax />
                <AboutusHeaderBanner />
                {/* <Testimonials /> */}
                <OurMissionBanner />
                <OurHighlightsAbout />
                <AboutContactUsBanner />
            </LayoutOne>
        </Fragment>
      );
    };
    
export default About;