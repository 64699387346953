import React, { Fragment } from 'react';
import ParallaxScrollLayer from '../../UI/Parallax/ParallaxScrollLayer';

const WelcomeHomeNote = () => {
  return (
    <Fragment>
      {/* <ParallaxScrollLayer
        //message={'hello'}
        imageUrl={`${process.env.PUBLIC_URL}/assets/images/banner-img-02.jpg`}
        backgroundPositionY={'bottom'}
        startingPosition={'80'}
        bannerHeight={'36vh'}
      /> */}
      <img src={process.env.PUBLIC_URL + '/assets/images/banner-img-02.jpg'} alt="" />
    </Fragment>
  );
};

export default WelcomeHomeNote;

