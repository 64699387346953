import React from 'react';
import SlickSlider from "../../UI/Carousel/Slick";
import FleetData from "../../../data/Fleet/FleetData.json";
import FleetItem from "./FleetItem";
import SectionTitle from '../../UI/SectionTitles/SectionTitle';


const NextArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="arrow_right"/></button>
    )
};

const PrevArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="arrow_left"/></button>
    )
};

const FleetSlider = () => {  

    const settings = {
        dots: false,
        arrows:true,
        infinite: true, 
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
          responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                }
              } 
 
            ]
      };

 
        return (

        <section className="fleet_section">
            <div className="fleet_section-container"> 
                {/* Heading */}
                <SectionTitle 
                    backgroundSuperHeaderColor="#edededf5"
                    title="Our Fleet" 
                />
                {/* End: Heading */}

                <div className="row" id="service"> 
                    <div className="col"> 
                        <SlickSlider settings={settings}>
                            {
                                FleetData.fleet.map(service => (
                                    <div key={service.id}>
                                        <FleetItem 
                                            key={service.id} 
                                            id={service.id} 
                                            title={service.carTitle} 
                                            image={service.vehicleImg} 
                                            category = {service.category} 
                                            carTitle = {service.carTitle}
                                            description = {service.description}
                                            passengers = {service.passengers}
                                            suitcases = {service.suitcases}
                                            onboardWifi = {service.onboardWifi}
                                            meetGreet = {service.meetGreet}
                                            wheelchairAvailable ={service.isWheelchairAvailable}
                                            wheelchair={service.wheelchair}
                                            boosterseat={service.booster_seat}
                                        // icon={service.icon}  
                                        // btnText={service.btnText} 
                                        />
                                    </div> 
                                ))
                            }
                        </SlickSlider>
                    </div> 
                </div> 
            </div> 
        </section>    
        );
    }
;

export default FleetSlider;

