import React, { Fragment } from "react";
import SectionTitle from "../UI/SectionTitles/SectionTitle";
import HeadingTitle from "../UI/HeadingTitle/HeadingTitle";
import BlockParagraph from "../UI/SectionParagraph/BlockParagraph";
import BlockPanelData from "../../data/OurHighlights/OurHighlightsAbout.json";

const OurHighlightsAbout = () => {

  const { sectionTitle, highlights } = BlockPanelData;

  const handleClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <Fragment>
      <div className="blockPanel-container">
        <div className="blockPanel-items-block">
          <SectionTitle
            title={sectionTitle.title}
           backgroundSuperHeaderColor={'#f3f3f3f5'} 
          // superHeaderColor = {superHeaderColor}
          // headerTitleColor = {headerTitleColor}
          ></SectionTitle>
        </div>
        <div className="blockPanel-items-block">
          {highlights.map((highlight, index) => (
            <div 
              key={index} 
              className="blockPanel-block shadedBlock-02"
              onClick={() => handleClick(highlight.href)} style={highlight.contentType === "link" ? { cursor: "pointer" } : {}}
            >
              <div className="blockPanel-icon">
                <i className={`highlight-icon fa ${highlight.icon}`}></i>
              </div>
              <div className="blockPanel-title">
                <HeadingTitle title={highlight.title} titleColor={'#ffc107'} textFontSize={22} />
              </div>
              <div className="blockPanel-text">
                <BlockParagraph paragraphTextColor={'#9b9b9b'} text={highlight.text} contentType={highlight.contentType} href={highlight.href} />
              </div>
            </div>
          ))}
        </div>
      </div>

    </Fragment>
  );
};

export default OurHighlightsAbout;


