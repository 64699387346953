import React, { Fragment } from 'react';

function Highlight({highlightContent, titleColor}) {

    const defaultTitleColor = "#816518";

    const titleStyle = {
      color: titleColor || defaultTitleColor 
    }


    return (
        <Fragment>
            <div className="highlight-content-block" style={titleStyle}>
                <span className="hightlight-bullet-point">
                    <span className="bullet-icon">✓</span> 
                    <span className="bullet-text" style={titleStyle}>{highlightContent}</span>
                </span>
            </div>
        </Fragment>
    );
}

export default Highlight;