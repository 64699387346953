import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import { emailJSServiceConfig } from "../../configurations/Email/emailJSServiceConfig";

class ContactFormItem extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      name: '',
      subject: '',
      message: '',
      email_err: '',
      name_err: '',
      subject_err: '',
      message_err: '',
      return_msg: '',
      flag: false,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  handleChangeName(e) {
    this.setState({ name: e.target.value });
  }

  handleChangeSubject(e) {
    this.setState({ subject: e.target.value });
  }

  handleChangeMessage(e) {
    this.setState({ message: e.target.value });
  }

  handleSubmit1() {

    let hasError = false;

    if (this.state.name === '') {
      this.setState({ name_err: 'Required Field' });
      hasError = true;
    } else {
      this.setState({ name_err: '' });
    }

    if (this.state.email === '') {
      this.setState({ email_err: 'Required Field' });
      hasError = true;
    } else {
      this.setState({ email_err: '' });
    }

    if (this.state.subject === '') {
      this.setState({ subject_err: 'Required Field' });
      hasError = true;
    } else {
      this.setState({ subject_err: '' });
    }

    if (this.state.message === '') {
      this.setState({ message_err: 'Required Field' });
      hasError = true;
    } else {
      this.setState({ message_err: '' });
    }

    if (hasError) {
      this.setState({ return_msg: 'Fill All Fields First', flag: true });
    } else {
      this.setState({ return_msg: 'Success.', flag: true });

      // Send email using EmailJS
      const templateParams = {
        to_email: emailJSServiceConfig.senderEmail,
        from_name: this.state.name,
        from_email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      };

      emailjs
        .send(emailJSServiceConfig.serviceID, emailJSServiceConfig.templateID, templateParams, emailJSServiceConfig.userID)
        .then(() => {
          this.setState({ return_msg: 'Email Sent Successfully', flag: true });
        })
        .catch((error) => {
          console.error('Email sending failed:', error);
          this.setState({ return_msg: 'Email Sending Failed', flag: true });
        });
    }
  }

  render() {
    return (
      <div className="contact-form">
        <div id="message">
          {this.state.flag ? (
            <div className="alert alert-success">
              <strong>{this.state.return_msg}</strong>
            </div>
          ) : null}
        </div>
        <form method="post" id="contactForm">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="input-group">
                <input
                  type="text"
                  id="name"
                  value={this.state.name}
                  onChange={this.handleChangeName}
                  className="form-control"
                  placeholder="Full Name:"
                />
                <span id="err">{this.state.name_err}</span>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  id="email"
                  placeholder="Email:"
                />
                <span id="err">{this.state.flag && this.state.email_err}</span>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  id="tel"
                  value={this.state.subject}
                  onChange={this.handleChangeSubject}
                  className="form-control"
                  placeholder="Subject:"
                />
                <span id="err">{this.state.flag && this.state.subject_err}</span>
              </div>
            </div>
            <div className="form-group col-md-6 col-sm-12">
              <div className="input-group input_group_full_width">
                <textarea
                  name="comments"
                  id="message"
                  value={this.state.message}
                  onChange={this.handleChangeMessage}
                  rows="6"
                  className="form-control"
                  placeholder="Message"
                ></textarea>
                <span id="err">{this.state.flag && this.state.message_err}</span>
              </div>
            </div>
            <div className="col-sm-12">
              <button
                type="button"
                name="send"
                onClick={this.handleSubmit1}
                className="submit-button btn btn-chos"
                value="Submit"
              >
                Submit
              </button>
              <div id="simple-msg"></div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactFormItem;

