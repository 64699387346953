import React, { Fragment } from "react";
import messageData from "../../../data/Services/SchoolsEventMessageData.json";
import HeadingTitle from "../../UI/HeadingTitle/HeadingTitle";
import Paragraph from "../../UI/SectionParagraph/Paragraph";

const SchoolsEventServiceCards = () => {
  return (
    <Fragment>
      <div className="service-card-container">
        <div className="card-inner-container">
          {messageData[0]["sub-message"].map((subMessage, index) => (
            <div className="service-card-tag shadedBlock-02" key={subMessage.id}>
              <div className="service-card-image">
                <img
                  className="service-card-img-wrapper"
                  src={process.env.PUBLIC_URL + `/assets/images/${subMessage["thumbnail-img"]}`}
                  alt="img-tag"
                />
              </div>
              <div className="service-card-content">
                <HeadingTitle title={subMessage["sub-message-title"]} titleColor={"#19b39f"} />
                <Paragraph text={subMessage.message} paragraphTextColor={"#816518"} textFontSize={"1.3vw"}  />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default SchoolsEventServiceCards;

