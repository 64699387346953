import React, { Fragment } from "react";

const Error = () => {
  const viewportWidth = window.innerWidth;
  let imageUrl;

  if (viewportWidth <= 480) {
    imageUrl = `${process.env.PUBLIC_URL}/assets/images/error-banner-small-01.jpg`;
  } else if (viewportWidth <= 768) {
    imageUrl = `${process.env.PUBLIC_URL}/assets/images/error-banner-medium-01.jpg`;
  } else if (viewportWidth <= 1024) {
    imageUrl = `${process.env.PUBLIC_URL}/assets/images/error-banner-medium-01.jpg`;
  } else {
    imageUrl = `${process.env.PUBLIC_URL}/assets/images/error-banner-01.jpg`;
  }

  return (
    <Fragment>
      <div className="error-page-container">
        <div className="error-page-message">Error: Something Went Wrong! Check Your URL</div>
        <div className="error-page-img">
          <img
            src={imageUrl}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: -1, // Adjust the zIndex as needed
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Error;