import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import Widget from "../../component/UI/Widget/Widget";
import List from "../../component/UI/List/List";
import LI from "../../component/UI/List/ListItem";

const Footer = () => {

    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);

    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };

    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="footer-blocks-wrapper">
                <div className="footer-block footer-main-block">
                    <div className="footer-main-subblook footer-subblock-a">
                        <div className="subfooter-socialmedia-block-wrapper">
                            <div className="subfooter-socialmedia-block">
                                <div className="footer_logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/logo-03.png'} alt="" style={{ width: '300px' }}/>
                                    </Link>
                                    <p>Name That You Can Trust</p>
                                </div>
                            </div>
                            {/* <div className="subfooter-socialmedia-block">
                                <div className="footer_social-container">
                                    <div className="footer_social">
                                        <ul className="footer_social_icons">
                                            <li> <a href="//facebook.com"><i className="fa fa-facebook"></i></a> </li>
                                            <li> <a href="//twitter.com"><i className="fa fa-twitter"></i></a> </li>
                                            <li> <a href="//instagram.com"><i className="fa fa-instagram"></i></a> </li>
                                            <li> <a href="//pinterest.com"><i className="fa fa-pinterest"></i></a> </li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="footer-main-subblook footer-subblock-b">
                        <div className="subfooter-link-block-wrapper">
                            <div className="subfooter-link-block">
                                <div className="subfooter-link-block-inner">
                                    <Widget title="Quick Link">
                                        <List classes="recent-post helpful_post">
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/about"}>Why choose us</Link>
                                            </LI>
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/services"}>Our service</Link>
                                            </LI>
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/your-council"}>Your Council</Link>
                                            </LI>
                                        </List>
                                    </Widget>
                                </div>
                            </div>
                            <div className="subfooter-link-block">
                                <div className="subfooter-link-block-inner">
                                    <Widget title="Services Link">
                                        <List classes="recent-post helpful_post">
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/services/school-transport"}>School Transport</Link>
                                            </LI>
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/services/additional-needs-transport"}>Additional Needs Transport</Link>
                                            </LI>
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/your-council"}>Local Council Transport</Link>
                                            </LI>
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/services/wheelchair"}>Wheelchair</Link>
                                            </LI>
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/services/social-care-transport"}>Social Care Transport</Link>
                                            </LI>
                                            <LI>
                                                <Link to={process.env.PUBLIC_URL + "/services/school-event"}>Schools Event</Link>
                                            </LI>
                                        </List>
                                    </Widget>
                                </div>
                            </div>
                            <div className="subfooter-link-block">
                                <div className="subfooter-contact-block-wrapper">
                                    <div className="subfooter-link-block-inner">
                                        <Widget title="Contact">
                                            <List classes="recent-post helpful_post">
                                                <LI>
                                                    <div className="subfooter-contact-block">
                                                        <div className="subfooter-contact-wrapper"><i className="icon_phone"></i><a href="tel:01491414040"> 01491 41 40 40</a></div>
                                                        <div className="subfooter-contact-wrapper"><i className="icon_phone"></i><a href="tel:07484093555"> 07484 09 35 55</a></div>
                                                    </div>
                                                </LI>
                                                <LI>
                                                    <div className="subfooter-contact-block">
                                                        <div className="subfooter-contact-wrapper"><i className="icon_pin_alt"></i><span> Regus | The Henley Building | Newtown Road | Henley-on-Thames | RG9 1HG</span></div>
                                                    </div>
                                                </LI>
                                            </List>
                                        </Widget>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
           </div>
        </div>
        <div className="subfooter-copyright-wrapper">      
            <div className="subfooter-copyright-block">
                <div className="copyright_text"> &copy; {new Date().getFullYear()} School Express </div>
            </div>

            <div className="subfooter-scollup-block">
                <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} >
                    <span className="arrow_triangle-up"></span>
                </button> 
            </div>            
        </div>
        <div className="poweredbyContainer">
            <div className="poweredByTitle">
                <a href="https://www.texanite.co.uk"> Powered By Texanite</a>
            </div>
        </div>
    </footer>
    )

}


export default Footer;


