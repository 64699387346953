import React, { Fragment } from "react";
import SplitBanner from "../UI/Banners/SplitBanner";

const FleetContactUsBanner = () => {
  return (
    <Fragment>
      <SplitBanner
        title="Contact Us"
        text="Explore our impressive fleet at School Express, where safety, vehicle reliability, and skilled drivers are our core values. We offer a wide range of vehicles to suit your needs. Contact us to ensure a secure and reliable journey with our expert team behind the wheel."
        backgroundBannerColor="#ffc107"
        backgroundSuperHeaderColor="#a7831742"
        headerTitleColor="#19b39f"
        paragraphTextColor="#816518"
        imageSrc={process.env.PUBLIC_URL + "/assets/images/banner-img-08.jpg"}
      />
    </Fragment>
  );
};

export default FleetContactUsBanner;
