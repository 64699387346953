import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";

import usePageTag from "../hooks/usePageTag";
import useScrollToSection from "../hooks/useScrollToSection";

import LayoutOne from "../component/Layouts/LayoutOne";
import HomeParallax from "../component/Home/Home-one/HomeParallax";
import AboutUsBanner01 from "../component/About/AboutUsBanner01";
import AboutUsBanner02 from "../component/About/AboutUsBanner02";
import WelcomeHomeNote from "../component/WelcomeNote/home-one/WelcomeHomeNote";
import Service from "../component/OurServices/home-one/Service";
import WhyChooseUs from "../component/WhyChooseUs/ChooseUs";
import HomeContactUsBanner from "../component/Home/Home-one/HomeContactUsBanner";
import OurHighlightsHome from "../component/OurHighlights/OurHighlightsHome";
import FleetSlider from "../component/Fleet/fleetSlider/FleetSlider";

const Home = () => {
  usePageTag("Home");

  const location = useLocation();
  

  useScrollToSection(location.hash.slice(1), -110);

  return (
    <Fragment>
      <LayoutOne>
        <HomeParallax />
        <AboutUsBanner02 />
        <AboutUsBanner01 />
        <Service />
        <WelcomeHomeNote />
        <WhyChooseUs />
        <OurHighlightsHome />
        <FleetSlider />
        <HomeContactUsBanner />
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
