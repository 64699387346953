import React, { Fragment} from "react";


const BlockParagraph = ({ text, paragraphTextColor, textFontSize, textJustify, contentType, href }) => {
  const defaultParagraphTextColor = "#ababab";

  const paragraphTextStyle = {
    color: paragraphTextColor || defaultParagraphTextColor
  };

  if (textFontSize) {
    paragraphTextStyle.fontSize = textFontSize;
  }

  if (textJustify) {
    paragraphTextStyle.textAlign = textJustify;
  }

  const content = contentType === "link" ? (
    <a href={href} style={paragraphTextStyle}>{text}</a>
  ) : (
    <p style={paragraphTextStyle}>{text}</p>
  );

  return (
    <Fragment>
      <div className="block-paragraph-container">
        <div className="block-paragraph-desc block-paragraph-text">
          {content}
        </div>
      </div>
    </Fragment>
  );
};

export default BlockParagraph;