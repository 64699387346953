import React, { Fragment } from "react";
import Highlight from "../Highlights/Highlight";

const SplitHighlightBannerItem = ({ content }) => {
  return (
    <Fragment>
      <div className="split-highlight-container">
        <div className="split-highlight-content">
          <Highlight highlightContent = {content} titleColor={"#19b39f"}/>
        </div>
      </div>
    </Fragment>
  );
};

export default SplitHighlightBannerItem;

