import React, { Fragment } from "react";
import SplitTextBanner from "../UI/Banners/SplitTextBanner";
import SplitTextBannerItem from "../UI/Banners/SplitTextBannerItem";
import Data from "../../data/About/AboutusHeaderBannerData.json"; 

const AboutusHeaderBanner = () => {
  return (
    <Fragment>
      <SplitTextBanner
        block1={{
          title: Data.block1.title,
          text: Data.block1.text,
          backgroundSuperHeaderColor:"#0000000a"
        }} 
        block2={{
          blockContent: (
            <div>
              {Data.block2.map((item, index) => ( 
                <SplitTextBannerItem
                  key={index} 
                  headerTitle={item.headerTitle}
                  paragraph={item.paragraph}
                />
              ))}
            </div>
          )
        }}
        button={{ 
          isButtonRequired: false, 
          buttonText: "Click Me!" 
        }}
      />
    </Fragment>
  );
};

export default AboutusHeaderBanner;

