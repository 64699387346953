import { useEffect } from "react";

const usePageTag = (pageTitle) => {
  const siteTitle = "School Express";

  useEffect(() => {
    document.title = siteTitle  + ' | ' + pageTitle ;
  }, [siteTitle, pageTitle]);
};

export default usePageTag;